import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect }  from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'

import Footer from '../partials/Footer'

import BillingDetails from '../components/BillingDetails'
import OrderDetails from '../components/OrderDetails'
import CheckoutPaymentMethod from '../components/CheckoutPaymentMethod'


//import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

class About extends Component {

	constructor(props) {
		
		super(props)

		this.state = {
		}

	}

	componentWillMount() {

		const self = this;
		
	}

	render() {

		//{ !this.state.isCheckoutAllowed ? ( <Redirect to="/" push /> ) : "" }
		
		return (
			<div>
				<Header />
				<HeadBanner title="About Us" />
				<BreadCrumbs pages={ [
					{
						title: "Home",
						link: "/"
					},
					{
						title: "About Us"
					}
				] } />
				<section class="outer about bg-white gray text14">
					<div class="container">
					 <div className="small-container">
						<div class="row">
							<div class="col-md-7 pt-4 mb-5">
								<h2 class={this.props.currentLang == 'en'? 'site-color': 'site-color zh_family about_title'}>
									<FormattedMessage id="Jenga Group" defaultMessage="Jenga Group"></FormattedMessage>
								</h2>
								<p className='about_content'>
								<FormattedMessage id="Jenga intro paragraph" defaultMessage="HQ in Singapore, Jenga is a go-to one-stop partner for global business expansion. With operating team in Singapore, China,Korea and US, we help clients solve business issues from corporate structuring and entity set up across multiple jurisdictions to global banking and compliance challenges. We also provide support for the marketing and communication strategy for different local markets, and we specialized in China, Korea, and Southeast Asia entry strategy."></FormattedMessage>	
								</p>
								<p className='about_content'>
									
								<FormattedMessage id="Jenga intro paragraph2" defaultMessage="Jenga is founded with the vision of uplifting tech-innovation and the entrepreneurship ecosystem by empowering technology start-ups and accelerating their successes. By teaming up with Jenga’s task force  with prior backgrounds in the blockchain industry, investment banking, venture capital, marketing, and management consulting, you will receive support in business planning, implementation and strategy advisory backed by industry-proven best practices to fuel your company’s long-term success."></FormattedMessage>
								</p>
								<p>
									
								<FormattedMessage id="Jenga intro paragraph3" defaultMessage="Find out more about">
								</FormattedMessage>
								&nbsp;<a href="https://www.jenga.io/" className="link"><span>Jenga BCG</span></a>
								</p>
								
								
							</div>
							<div class="col-md-5 pt-5 pl-md-5 mt-md-4">
								<img src="images/about.png" alt="about" class="img-fluid" />
							</div>							
						</div>
						
						<div class="row">
							<div class="col-md-8 pt-5">
								<h3 class="small-heading">
									{
										this.props.currentLang == 'en' ? (
											<span class="site-color"><FormattedMessage id="Our Business" defaultMessage="Our Business"></FormattedMessage></span>
										) : (
											<span class="site-color zh_family">Jenga商业咨询集团旗下 <br/> 四大业务主体</span>
										)
									}
                  {/* <span class={this.props.currentLang == 'en'? 'site-color': 'site-color zh_family'}><FormattedMessage id="Our Business" defaultMessage="Our Business"></FormattedMessage></span> */}
								 
								 </h3>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 pt-5">
						        <h2 class={this.props.currentLang == 'en'? 'dark-gray heading-light text-center': 'dark-gray heading-light text-center zh_family about_itemTitle'}>
									<FormattedMessage id="CORPORATE SERVICES" defaultMessage="CORPORATE SERVICES"></FormattedMessage>
								</h2>
						    </div>
					    </div>
						<div class="row pt-5 pb-5">
						    <div class="col-md-6">
							  <div class="graybox">
								<div class="text-center"><img src="/images/jenga-corp.png"  alt="Jenga Corp" width="89" /></div>
								<div class="mt-5">
									<h5 class="heading-light text18"><FormattedMessage id="Jenga Corp" defaultMessage="JENGA CORP"></FormattedMessage></h5>
									<p class="card-text">
									{
										this.props.currentLang == 'en'? (
											<FormattedMessage id="Jenga card text1" defaultMessage="A Singapore ACRA certified corporate secretary and accounting service firm focused on providing incorporation andoperational support to global technology companies who come to Singapore for global expansion. Differentiating from thetraditional corporate secretary service provider, Jenga Corp committed to providing timely and digital solutions for the high-efficiency demand of technology startups. Under the knowledge sharing environment in Jenga BCG, Jenga Corp's professional team are well trained to not only provide specialized services in their profession but also understand the industry and business nature of the clients. Jenga Corp has been providing professional support to thousands of technology companies worldwide. Our clients benefit not only from our professional service on setup their business, but also our knowledge and global networks from our entire business group."></FormattedMessage>
										):(
											<div>
												<p>“简客秘书（Jenga Corp）” 持新加坡ACRA（会计和企业管理局） 法定秘书资质，凭借团队在行业内数十年的专业背景和实力，专注于为全球客户提供数字化的公司治理和综合企业服务，助力于实现跨国公司的无纸化运营，业务内容包括：海外主体架构设计，在线注册新加坡和离岸公司、公司和个人国际银行帐户开设、股权架构设计和股东治理、增资扩股和员工期权设计，跨境架构智能秘书服务、会计记帐和财务报表服务，年报年审服务。</p>
												<p>为了解决传统海外公司注册和秘书服务流程复杂冗长、信息不可控、服务不透明的问题，Jenga团队综合多年行业经验，用科技手段对传统业务进行数字化升级，使得全球用户能通过Jenga Corp网站和用户端实现7/24自助下单注册海外公司、在线提交文件自动KYC、智能填表完成电子签署、股东董事控制面板共享公司证书和文件库、公司日历提醒按时年审年报、企业和股东信息尽职调查的一站式服务。全球用户可以在世界任何地方轻松完成海外公司注册、随时查询名下控股信息、自助完成股东大会和各类股权变更管理。</p>
												<p>“简客秘书” 高效、透明和数字化的秘书服务让其在行业内脱颖而出，获得全球数百家科技公司、股权基金、律师事务所和信托管理人的亲睐和推崇。作为Jenga BCG旗下的一员，受益于其丰富而多元的业务内容和客户资源，简客秘书的客户们不仅能获得高效而专业的数字秘书和会计服务，并能Jenga大家庭中汲取可靠的信息资讯和共享广泛的人脉网络，获得出海经营和全球扩张的可靠资源和伙伴。</p>
											</div>
										)
									}
                                   {/* <FormattedMessage id="Jenga card text1" defaultMessage="A Singapore ACRA certified corporate secretary and accounting service firm focused on providing incorporation andoperational support to global technology companies who come to Singapore for global expansion. Differentiating from thetraditional corporate secretary service provider, Jenga Corp committed to providing timely and digital solutions for the high-efficiency demand of technology startups. Under the knowledge sharing environment in Jenga BCG, Jenga Corp's professional team are well trained to not only provide specialized services in their profession but also understand the industry and business nature of the clients. Jenga Corp has been providing professional support to thousands of technology companies worldwide. Our clients benefit not only from our professional service on setup their business, but also our knowledge and global networks from our entire business group."></FormattedMessage> */}

									</p>
								</div>
							</div>
							</div>
							
							<div class="col-md-6">
								<div class="graybox">
								<div class="text-center" style={{height:89+'px'}}><img src="/images/jenga-hr.png"  alt="Jenga HR" width="79" height="79" /></div>
								<div class="mt-5">
									<h5 class="heading-light text18"><FormattedMessage id="JENGA HUMAN RESOURCES" defaultMessage="JENGA HUMAN RESOURCES"></FormattedMessage></h5>
									<p class="card-text">

                                   <FormattedMessage id="A Singapore MOM" defaultMessage="A Singapore MOM (Ministry of Manpower) registered employment agent and a fully-owned subsidiary under Jenga Corp Pte. Ltd, Jenga HR is a specialist recruitment and human resources services firm for companies in the finance, banking, blockchain and Fintech industries. Our approach is to combine the use of digital solutions with the expert knowledge of our consultants who have either a professional background or industry experience in the specialist recruitment sector. Differentiated from traditional service providers, Jenga Human Resource provides timely and digital solutions for the high-efficiency demands of technology start-ups and global enterprises."></FormattedMessage>

									</p>
								</div>
							</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 pt-5">
						        <h2 class={this.props.currentLang == 'en'? 'dark-gray heading-light text-center': 'dark-gray heading-light text-center zh_family about_itemTitle'}>
									<FormattedMessage id="STRATEGIC BUSINESS CONSULTING" defaultMessage="STRATEGIC BUSINESS CONSULTING"></FormattedMessage>
								</h2>
						    </div>
					    </div>
						<div class="row pt-5 pb-5" style={this.props.currentLang == 'en'? {display:'flex',justifyContent:'center'}: {fontFamily:'华文细黑',display:'flex',justifyContent:'center'}}>							
							{/* <div class="col-md-4 col-sm-6">
							   <div class="graybox">
								<div class="h76 text-center"><img class="img-fluid" src="/images/jenga-s.png"  alt="Jenga solutions" width="79" /></div>
								<div class="mt-4">
									<h5 class="text18 heading-light" style={this.props.currentLang == 'en'? {}: {fontWeight:'600'}}><FormattedMessage id="JENGA SOLUTIONS" defaultMessage="JENGA SOLUTIONS"></FormattedMessage></h5>
									<p class="card-text">
                               // 要注释的 <FormattedMessage id="A Singapore-based" defaultMessage="A Singapore-based blockchain business consulting and technology service provider working in partnership with Ziggurat Technology China. Our business and technology team works closely with clients’ projects long term to achieve their business objectives."></FormattedMessage>
							   <FormattedMessage id="Jenga provides1" defaultMessage="Jenga provides the most in-demand services in Singapore targeting global business owners and High Net-Worth Individuals (HNWIs) who wish to expand their venture in Singapore for its advanced regulatory framework for finance and technology businesses, as well as its attractive tax scheme for fund management and family offices."></FormattedMessage>
								</p>
								<p class="card-text">
								<FormattedMessage id="Jenga provides2" defaultMessage="With a precise strategic positioning focused on Global Fin-tech and HNWIs, together with a strong team and business operations, the company has grown into five business units under the globally patented brand JENGA"></FormattedMessage>
								<sub  style={this.props.currentLang == 'en'? {}: {display:'none'}}>TM</sub>
								<FormattedMessage id="Jenga provides2-1" defaultMessage=",serving 3,000+ business owners and 100+ family offices and 120+ investment fund worldwide."></FormattedMessage>
									</p>
								</div>
								</div>
							</div> */}
							<div class="col-md-4 col-sm-6">
							  <div class="graybox">
								<div class="h76 text-center"><img class="img-fluid" src="/images/jenga-ad.png"  alt="Jenga" width="70" height="77" /></div>
								<div class="mt-4">
									<h5 class="text18 heading-light" style={this.props.currentLang == 'en'? {}: {fontWeight:'600'}}><FormattedMessage id="JENGA ADVISORS LLC" defaultMessage="JENGA ADVISORS LLC"></FormattedMessage></h5>
									<p class="card-text">

                           <FormattedMessage id="A US-based" defaultMessage="A US-based regulatory strategy firm focused on advising blockchain companies concerning regulatory compliant token projects and secondary market listings on trading platforms.  Jenga Advisors is led by Kevin Batteh, former CFTC lawyer."></FormattedMessage> 
									</p>
								</div>
						      </div>
							</div>
							{/* <div class="col-md-4 col-sm-6">
							 <div class="graybox">
								<div class="h76 text-center"><img class="img-fluid mt-4 " src="/images/jenga-k1.png"  alt="jenga-k1" width="119" height="46" /></div>
								<div class="mt-4">
									<h5 class="text18 heading-light" style={this.props.currentLang == 'en'? {}: {fontWeight:'600'}}><FormattedMessage id="JENGA KOREA" defaultMessage="JENGA KOREA"></FormattedMessage></h5>
									<p class="card-text"><FormattedMessage id="Jenga Korea focuses" defaultMessage="Jenga Korea focuses on the inbound outbound business opportunities in  Korea, providing services across Jenga’s suite of business solutions – whether to local Korean companies expanding overseas, or global technology companies seeking entry into the Korean market.


"></FormattedMessage>
									</p>
								</div>
						      </div>
							</div> */}
						</div>
						<div class="row mt-4 pt-5 mt-md-5 mb-md-4">
							<div class="col-md-8 offset-md-2 pt-5 text-center" style={this.props.currentLang == 'en'? {}: {fontFamily:'华文细黑'}}>
								<h3 class="small-heading mb-4 pb-2 noMarBottom" style={this.props.currentLang == 'en'? {}: {fontWeight:'600'}}><FormattedMessage id="Our Team" defaultMessage="Our Team"></FormattedMessage></h3>
								<p>
								<FormattedMessage id="Team Content" defaultMessage="We’re a group of people passionate about unlocking the tools for a happy mouth and healthier you.We believe in simplicity, affordability and fun."></FormattedMessage>	

								</p>
							</div>
						</div>
						<div class="row mt-4 pb-5">
							<div class="col-md-12">
								<div class="box team-member">
									<ul class="team-column d-flex flex-wrap align-items-end">
										<li>
											<div class="team-thumb"><img src="images/ceo.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="lris" defaultMessage="lris"></FormattedMessage></h4>
												<p><FormattedMessage id="Founder, CEO" defaultMessage="Founder, CEO"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Sherly.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Sheryl" defaultMessage="Sheryl"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Corporate Advisor" defaultMessage="Senior Corporate Advisor"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Kobe.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Kobe" defaultMessage="Kobe"></FormattedMessage></h4>
												<p><FormattedMessage id="Internal Accounts Manager" defaultMessage="Internal Accounts Manager"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Joleen.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Joleen" defaultMessage="Joleen"></FormattedMessage></h4>
												<p><FormattedMessage id="Operations Manager/Executive Assistant to CEO" defaultMessage="Operations Manager/Executive Assistant to CEO"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Jiajin.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Jia Jin" defaultMessage="Jia Jin"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Finance Manager" defaultMessage="Senior Finance Manager"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Andrew.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Andrew" defaultMessage="Andrew"></FormattedMessage></h4>
												<p><FormattedMessage id="Accounts Manager" defaultMessage="Accounts Manager"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/WangRuonan.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Rona" defaultMessage="Rona"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Business Consultant" defaultMessage="Senior Business Consultant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Miko.png" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Miko" defaultMessage="Miko"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Business Consultant" defaultMessage="Senior Business Consultant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Nikki.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Nikki" defaultMessage="Nikki"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Business Consultant" defaultMessage="Senior Business Consultant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Vivian.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Vivian" defaultMessage="Vivian"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Business Consultant" defaultMessage="Senior Business Consultant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Irene.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Irene" defaultMessage="Irene"></FormattedMessage></h4>
												<p><FormattedMessage id="Accountant" defaultMessage="Accountant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/YeeSin.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Yee Sin" defaultMessage="Yee Sin"></FormattedMessage></h4>
												<p><FormattedMessage id="Accountant" defaultMessage="Accountant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Chengjie.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Chengjie" defaultMessage="Chengjie"></FormattedMessage></h4>
												<p><FormattedMessage id="Accountant" defaultMessage="Accountant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Jessie.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Jessie" defaultMessage="Jessie"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Admin Executive" defaultMessage="Corporate Admin Executive"></FormattedMessage></p>
											</div>
										</li>										
										<li>
											<div class="team-thumb"><img src="images/YuCheng.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="YuCheng" defaultMessage="YuCheng"></FormattedMessage></h4>
												<p><FormattedMessage id="Compliance Manager" defaultMessage="Compliance Manager"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Joyce.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Joyce" defaultMessage="Joyce"></FormattedMessage></h4>
												<p><FormattedMessage id="HR & Admin Executive" defaultMessage="HR & Admin Executive"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Jasmine.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Jasmine" defaultMessage="Jasmine"></FormattedMessage></h4>
												<p><FormattedMessage id="HR & Admin Executive" defaultMessage="HR & Admin Executive"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Ezra.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Ezra" defaultMessage="Ezra"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Secretarial Manager" defaultMessage="Corporate Secretarial Manager"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/YuCi.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Yu Ci" defaultMessage="Yu Ci"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Secretarial Assistant Manager" defaultMessage="Corporate Secretarial Assistant Manager"></FormattedMessage></p>
											</div>
										</li>
                    <li>
											<div class="team-thumb"><img src="images/Emily.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Emily" defaultMessage="Emily"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Secretarial Executive" defaultMessage="Corporate Secretarial Executive"></FormattedMessage></p>
											</div>
										</li>
                    <li>
											<div class="team-thumb"><img src="images/Chloe.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Chloe" defaultMessage="Chloe"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Secretarial Executive" defaultMessage="Corporate Secretarial Executive"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/ui.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Lynn" defaultMessage="Lynn"></FormattedMessage></h4>
												<p><FormattedMessage id="Marketing & Communications" defaultMessage="Marketing & Communications"></FormattedMessage></p>
											</div>
										</li>
										{/* <li>
											<div class="team-thumb"><img src="images/tom.png" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Tom" defaultMessage="Tom"></FormattedMessage></h4>
												<p><FormattedMessage id="CTO" defaultMessage="CTO"></FormattedMessage></p>
											</div>
										</li> */}
										{/* <li>
											<div class="team-thumb"><img src="images/YiEn.png" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Yi’En" defaultMessage="Yi’En"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Secretary & Compliance Manager" defaultMessage="Corporate Secretary & Compliance Manager"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Stefan.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Stefan" defaultMessage="Stefan"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Business Consultant" defaultMessage="Senior Business Consultant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Stanley.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Stanley" defaultMessage="Stanley"></FormattedMessage></h4>
												<p><FormattedMessage id="Business Consultant" defaultMessage="Business Consultant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/YeeSin.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Yee Sin" defaultMessage="Yee Sin"></FormattedMessage></h4>
												<p><FormattedMessage id="Accountant" defaultMessage="Accountant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Yuni.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Yuni" defaultMessage="Yuni"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Secretary Executive" defaultMessage="Corporate Secretary Executive"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Stephanie.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Stephanie" defaultMessage="Stephanie"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Secretary Executive" defaultMessage="Corporate Secretary Executive"></FormattedMessage></p>
											</div>
										</li> */}
										{/* <li>
											<div class="team-thumb"><img src="images/Doris.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Doris" defaultMessage="Doris"></FormattedMessage></h4>
												<p><FormattedMessage id="Corporate Secretary Manager" defaultMessage="Corporate Secretary Manager"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Amanda.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Amanda" defaultMessage="Amanda"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Business Consultant" defaultMessage="Senior Business Consultant"></FormattedMessage></p>
											</div>
										</li>
										<li>
											<div class="team-thumb"><img src="images/Ariel.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Ariel" defaultMessage="Ariel"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Business Consultant" defaultMessage="Senior Business Consultant"></FormattedMessage></p>
											</div>
										</li> */}
										{/* <li>
											<div class="team-thumb"><img src="images/Wilberina.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Wilberina" defaultMessage="Wilberina"></FormattedMessage></h4>
												<p><FormattedMessage id="Business Consulting" defaultMessage="Business Consulting"></FormattedMessage></p>
											</div>
										</li> */}

										{/* <li>
											<div class="team-thumb"><img src="images/JamieNeo.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Jamie Neo" defaultMessage="Jamie Neo"></FormattedMessage></h4>
												<p><FormattedMessage id="Office Admin Executive" defaultMessage="Office Admin Executive"></FormattedMessage></p>
											</div>
										</li> */}


										{/* <li>
											<div class="team-thumb"><img src="images/Elieen.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Elieen Chong" defaultMessage="Elieen Chong"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior HR and Operations Manager" defaultMessage="Senior HR and Operations Manager"></FormattedMessage></p>
											</div>
										</li> */}
										{/* <li>
											<div class="team-thumb"><img src="images/johnson.png" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Johnson" defaultMessage="Johnson"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Financial Officer" defaultMessage="Senior Financial Officer"></FormattedMessage></p>
											</div>
										</li> */}
										{/* <li>
											<div class="team-thumb"><img src="images/bc.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Poppy" defaultMessage="Poppy"></FormattedMessage></h4>
												<p><FormattedMessage id="BC/VP" defaultMessage="BC/VP"></FormattedMessage></p>
											</div>
										</li> */}
										{/* <li>
											<div class="team-thumb"><img src="images/johnson.png" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Johnson" defaultMessage="Johnson"></FormattedMessage></h4>
												<p><FormattedMessage id="Senior Financial Officer" defaultMessage="Senior Financial Officer"></FormattedMessage></p>
											</div>
										</li> */}
										
										{/* <li>
											<div class="team-thumb"><img src="images/ca.jpg" alt="" /></div>
											<div class="team-info">
												<h4><FormattedMessage id="Sarah" defaultMessage="Sarah"></FormattedMessage></h4>
												<p><FormattedMessage id="CA/CS" defaultMessage="CA/CS"></FormattedMessage></p>
											</div>
										</li> */}	
									</ul>
								</div>
							</div>
						</div>
					</div>
				  </div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(About)